/*jslint browser: true, nomen: true */
/*global $ */



function PageTimer() {
    var self = this;
    this.getLoadTime = function () {
        var now = new Date().getTime();
        // Get the performance object
        window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
        var timing = performance.timing || {};

        var ms = now - timing.navigationStart;

        return Math.round(ms / 10) / 100;
    };

    this.logToConsole = function () {
        $(window).on('load', function () {
            console && console.info && console.info("Loaded in " + self.getLoadTime() + 's');
        });
    };

    this.append = function (holder) {
        $(window).on('load', function () {
            holder.text(' | LT: ' + self.getLoadTime() + 's');
        });
    };
}

function ExternalLinkHandler() {
    var self = this;
    var hostname = document.location.hostname;

    this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

    this.addTargetAttribute = function (context) {
        context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
            var anchor = $(this);
            var href = anchor.attr('href');
            var isInternalLink = false;

            for (var i = 0; i < self.matchInternalLink.length; i++) {
                var regex = self.matchInternalLink[i];
                if (href.match(regex)) {
                  isInternalLink = true;
              }
            }

            if (!isInternalLink) {
                anchor.attr('target', '_blank').addClass('external-link');
            }
        });
    };
}

function StickyFooter($wrap, $footer) {
    var $window = $(window);

    this.updateWrapperCSS = function (evt) {
        var footerHeight = $footer.outerHeight();

        $wrap.css({
            marginBottom: -1 * footerHeight,
            paddingBottom: footerHeight
        });

        if (typeof evt !== 'undefined' && evt.type === 'load') {
            $footer.removeClass('page-loading');
        }

    };

    this.bindOnResize = function () {
        $window.on('resize', this.updateWrapperCSS);
        return this;
    };

    this.init = function () {
        $window.on('load', this.updateWrapperCSS);
        return this;
    };
}

function scrollToElement(options) {
    var id, speed = 750, offset = 0, targetOffset, data, selector;

    selector = options.selector;
    speed = options.speed ? options.speed : speed;
    offset = options.offset ? options.offset : offset;

    targetOffset = $(selector).offset().top - offset;

    $('html,body').animate({scrollTop: targetOffset}, speed, function () {});
}


function UIBindings() {
 
    this.bindTooltips = function () {
        $('[data-toggle="tooltip"]').tooltip();
    };

    this.bindPopovers = function () {
        $('[data-toggle="popover"]').popover();
    };

    this.bindMagnificPopup = function () {
        $('[data-magnific-image]').magnificPopup({
            type: 'image'
        });
    };

   
    this.bindSharing = function(){
        $("[data-share-default]").each(function () {
            var shareHandler = new ShareHandler($(this), true);
            shareHandler.appendFacebook();
            shareHandler.appendTwitter();
        });
    };

    this.bindEvents = function(){
    
        $('#back-top').topLink({
            min: 400,
            fadeSpeed: 500
        }).on('click','a', function (e) {
            e.preventDefault();
            scrollToElement({selector: "#header", speed: 300});
        });

        $('.search-box-activate').on('click', function() {
            $('.search-box-form').addClass('active');
        });

        $('h2.firstWord,h1.firstWord').each(
          function(){
              var that = $(this),
                  newText = that.text().replace(/(^\w+|\s+\w+)/,'<strong>$1</strong>');
              that.html(newText);
        });
    };

}

$.fn.exists = function () {
    return $(this).length > 0;
};

// scroll to top og page
$.fn.topLink = function (settings) {
    settings = $.extend({
        min: 1,
        fadeSpeed: 200,
        ieOffset: 50
    }, settings);

    return this.each(function () {
        //listen for scroll
        var el = $(this);
        el.css('display', 'none'); //in case the user forgot

        $(window).scroll(function () {
      
            if ($(window).scrollTop() >= settings.min) {
                el.fadeIn(settings.fadeSpeed);
            }
            else {
                el.fadeOut(settings.fadeSpeed);
            }
        });
    });
};


$(function () {
    'use strict';

    // socialShare();

    (new UIBindings).bindTooltips();
    (new UIBindings).bindPopovers();
    (new UIBindings).bindMagnificPopup();
    (new UIBindings).bindEvents();
    (new UIBindings).bindSharing();
    (new SVGMap).bindEvents();
    (new ExternalLinkHandler).addTargetAttribute($('body'));
    (new PageTimer).logToConsole();

    (new StickyFooter($('#wrap'), $('#footer'))).init().bindOnResize();
    
});

$('section.taskforce-tabs > ul.nav.nav-tabs > li:first-of-type').addClass('active');
$('section.taskforce-tabs > div.tab-content > div.tab-pane:first-of-type').addClass('in active');

$.fn.exists = function () { return $(this).length > 0; };

function SVGMap () {
    var svg = document.querySelector("#africa-map"),
        svgMap = $('#africa-map'),
        svgParent = svgMap.parent(),
        mapItems = $('[data-svg-map-item]'),
        paths,
        elements = [];

    if (svg && svgMap.exists()) {

        paths = svg.querySelectorAll('path');

        svg.addEventListener('load', function () {
            mapItems.each(function () {
                var listItem = $(this);
                var id =   listItem.data('svgMapItem');
                var path = svg.querySelector('#' +id);
               

                if (path) {
                    
                    var svgElement = (new SVGElements(path));
                    elements[id] = svgElement;
                    svgElement.addClass('pop-up');

                }
            });
        });
    }

    this.bindEvents = function() {

        mapItems.on('mouseover', function(){
            var path = $(this).data('svgMapItem');

            var current = elements[path];

            for (var key in elements) {
                elements[key].removeClass('active').addClass('pop-up');
            }

            if (current) {
                current.addClass('active').removeClass('pop-up');
            }

            return false;
        }).on('mouseout', function () {
            var path = $(this).data('svgMapItem');
            var current = elements[path];

            current.addClass('pop-up').removeClass('active');
        }).on('click', function () {

        });


        $(paths).on('mouseover', function(){
            var id = $(this).attr('id');
            mapItems.removeClass('active').filter(function(){
                return $(this).data('svgMapItem') === id;
            }).addClass('active');
        }).on('mouseout', function(){
            var id = $(this).attr('id');
            mapItems.filter(function(){
                return $(this).data('svgMapItem') === id;
            }).removeClass('active');
        });

        $(document).on('click','[data-dismiss]', function (evt) {
            var id = $(this).data('dismiss');
            svgMap.find('#' +id).popover('hide');
        })
        
    };

    function Popovers(listItem, mapItem) {


        var id = listItem.data('svgMapItem');
        var content =  $('[data-map-content="'+ id +'"]').html();

        var options = {
            content: function () {
                return content;
            },
            html: true,
            placement: 'top',
            container: '[data-map]',
            trigger: 'focus'
        };


        listItem.on('click', function (evt) {
            if (svgParent.is(':visible')) {

                $(mapItem).trigger('click');
                return false;
            }
        });

        $(mapItem)
            .popover(options)
            .on('inserted.bs.popover', function () {
                $(document).find('[data-magnific-inline]').magnificPopup({
                    type: 'inline',
                    fixedContentPos: true,
                    fixedBgPos: true,
                    overflowY: 'auto',
                    closeBtnInside: true,
                    preloader: false,
                    midClick: true,
                    removalDelay: 300,
                    mainClass: 'my-mfp-slide-bottom'
                });
            })
            .on('click', function (evt) {
                $(paths).popover('hide');
                $(evt.target).popover('toggle');
            });
    }
}

function SVGElements(el) {

    var self = this;


    this.hasClass = function (name) {
        return new RegExp('(\\s|^)' + name + '(\\s|$)').test(get());
    };

    this.addClass = function (name) {
        !self.hasClass(name) && el.setAttribute('class', (get() && get() + ' ') + name);
        return this;
    };

    this.removeClass = function (name) {
        var news = get().replace(new RegExp('(\\s|^)' + name + '(\\s|$)', 'g'), '$2');
        self.hasClass(name) && el.setAttribute('class', news);
        return this;
    };

    this.toggle = function (name) {
        (self.hasClass(name) ? self.removeClass : self.addClass)(name);
    };

    function get() {
        return el.getAttribute('class') ? el.getAttribute('class') : '';
    }
}